import Vuex from "vuex";
import auth from "./modules/auth";

export default new Vuex.Store({
  state: {
    loading: false,
  },
  getters: {},
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    login: function () {
      console.log("Don't call me in the afternoon. Im resting ...!!!");
    },
  },

  modules: {
    auth,
  },
});
