import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: {
      name: "vendorLogin",
    },
  },
  {
    path: "/vendor-login",
    name: "vendorLogin",
    meta: {
      loginAuth: true,
    },
    component: () => import("../components/global/authentication/TheLogin.vue"),
  },
  {
    path: "/vendor-register",
    name: "RegisterVendor",
    meta: {
      loginAuth: true,
    },
    component: () =>
      import("../components/global/authentication/registerVendor.vue"),
  },
  {
    path: "/admin-secure-login",
    name: "admin-login",
    meta: {
      loginAuth: true,
    },
    component: () =>
      import("../components/global/authentication/adminLogin.vue"),
  },
  {
    path: "/",
    name: "TheDashboard",
    component: () => import("../layouts/TheLayout.vue"),
    redirect: {
      name: "MainDashboard",
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "MainDashboard",
        component: () => import("../views/home/TheDashboard.vue"),
        meta: {
          requiresAuth: true,
          roles: ["Admin", "Vendor"], // Add Vendor role here
        },
      },
      {
        path: "users",
        name: "TheUsers",
        component: () => import("../views/users/TheUsers.vue"),
        meta: {
          requiresAuth: true,
          roles: ["Admin"],
        },
        children: [
          {
            path: "",
            name: "UserList",
            component: () => import("../components/users/userList.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "create",
            name: "createUserPage",
            component: () => import("../components/users/createUser.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "update/:item",
            name: "userUpdatePage",
            component: () => import("../components/users/updateUser.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
        ],
      },
      {
        path: "vendor",
        name: "Vendor",
        component: () => import("../views/vendor/TheVendor.vue"),
        meta: {
          requiresAuth: true,
          roles: ["Admin"],
        },
        children: [
          {
            path: "",
            name: "VendorList",
            component: () => import("../components/vendor/VendorList.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "create",
            name: "createVendorPage",
            component: () => import("../components/vendor/createVendor.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "update/:item",
            name: "vendorUpdatePage",
            component: () => import("../components/vendor/updateVendor.vue"),
            meta: { requiresAuth: true, roles: ["Admin"] },
          },
        ],
      },
      {
        path: "category",
        name: "TheCategory",
        component: () => import("../views/category/TheCategory.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "CategoryList",
            component: () => import("../components/category/categoryList.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "createCategoryPage",
            component: () =>
              import("../components/category/createCategory.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "update/:item",
            name: "updateCategoryPage",
            component: () =>
              import("../components/category/updateCategory.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "update-sub-category/:item",
            name: "updateSubCategoryPage",
            component: () =>
              import("../components/category/updateSubCategory.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "view/:item",
            name: "viewCategoryPage",
            component: () => import("../components/category/viewCategory.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "products",
        name: "ProductPage",
        component: () => import("../views/products/TheProducts.vue"),
        meta: {
          requiresAuth: true,
          // roles: ["Vendor"],
        },
        children: [
          {
            path: "",
            name: "productList",
            component: () => import("../components/products/productList.vue"),
            meta: {
              requiresAuth: true,
              // roles: ["Vendor"],
            },
          },
          {
            path: "create",
            name: "createProductPage",
            component: () => import("../components/products/createProduct.vue"),
            meta: {
              requiresAuth: true,
              // roles: ["Vendor"],
            },
          },
          {
            path: "create-bulk",
            name: "createBulkProductPage",
            component: () =>
              import("../components/products/createBulkProduct.vue"),
            meta: {
              requiresAuth: true,
              // roles: ["Vendor"],
            },
          },
          {
            path: "update/:item",
            name: "updateProductPage",
            component: () =>
              import("../components/products/updateProducts.vue"),
            meta: {
              requiresAuth: true,
              // roles: ["Vendor"],
            },
          },
        ],
      },
      {
        path: "settings",
        name: "TheSettings",
        component: () => import("../views/settings/TheSettings.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "updateProfile",
            component: () => import("../components/settings/updateProfile.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "inquiry",
        name: "TheInquiry",
        component: () => import("../views/inquiry/TheInquiry.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "InquiryList",
            component: () => import("../components/inquiry/inquiryList.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "contact-us",
        name: "TheContactUs",
        component: () => import("../views/contactus/TheContactUs.vue"),
        meta: {
          requiresAuth: true,
        },
        roles: ["Admin"],
        children: [
          {
            path: "",
            name: "ContactUsList",
            component: () =>
              import("../components/contactus/contactusLIst.vue"),
            meta: {
              requiresAuth: true,
            },
            roles: ["Admin"],
          },
        ],
      },
      {
        path: "sliders",
        name: "SliderPage",
        component: () => import("../views/slider/TheSlider.vue"),
        meta: {
          requiresAuth: true,
          roles: ["Admin"],
        },
        children: [
          {
            path: "",
            name: "sliderList",
            component: () => import("../components/sliders/sliderList.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "create",
            name: "createSliderPage",
            component: () => import("../components/sliders/createSlider.vue"),
            meta: {
              requiresAuth: true,
              roles: ["Admin"],
            },
          },
          {
            path: "update/:item",
            name: "updateSliderPage",
            component: () => import("../components/sliders/updateSlider.vue"),
            meta: {
              requiresAuth: true,
              // roles: ["Vendor"],
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");

  if (to.matched.some((record) => record.meta.loginAuth)) {
    if (auth) {
      next({ name: "TheDashboard" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth) {
      next({ name: "vendorLogin" });
    } else {
      if (to.meta.roles && !to.meta.roles.includes(userRole)) {
        if (userRole === "Admin") {
          next({ name: "MainDashboard" });
        } else {
          next({ name: "CategoryList" });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
