export default {
  state: {
    token: "",
    user: {},
  },
  getters: {
    // getToken: (state) => {
    //   return state.token;
    // },
    // getUser: (state) => {
    //   return state.user;
    // },
  },
  mutations: {
    // SET_TOKEN(state, payload) {
    //   state.token = payload;
    // },
    // SET_USER(state, payload) {
    //   state.user = payload;
    // },
  },
  actions: {
    login: function () {
      console.log("Don't call me in the afternoon. Im resting ...!!!");
    },
  },
};
