<template>
  <v-app class="main-content">
    <v-main class="content" :class="!checkLoginRoute ? 'layout-fix' : ''">
      <v-container fluid :class="checkLoginRoute && 'pa-0'">
        <Theloader :loading="loading" />
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Theloader from "./views/TheLoader.vue";
export default {
  name: "App",

  data() {
    return {
      isDrawer: false,
    };
  },
  components: {
    Theloader,
  },
  created() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      localStorage.token;
      if (localStorage.token) {
        this.isDrawer = true;
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    checkLoginRoute() {
      return (
        this.$route.path == "/vendor-login" ||
        this.$route.path == "/admin-secure-login" || 
        this.$route.path == "/vendor-register"
      );
    },
  },
  watch: {
    $route() {
      this.checkToken();
    },
  },
};
</script>
<style>
.v-data-table-header__content {
  font-weight: bold;
}
.main-content {
  background-color: #f8f7fc !important;
}
.v-toast {
  z-index: 200000 !important;
}
.layout-fix{
  --v-layout-left: 230px !important;
}
.title-card {
  padding-top: 17px;
  padding-bottom: 17px;
}
</style>
