import { createApp } from "vue";
import App from "./App.vue";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import router from "./router/index";
import axios from "axios";
import "@/assets/css/main.css"
import VueAxios from "vue-axios";
import store from "./store/index";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", 
  },
});

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(ToastPlugin,{
  position: 'top-right',
  duration:3000,
  dismissible:true
});
app.use(store);
app.use(VueAxios, axios);
app.component('QuillEditor', QuillEditor)
app.mount("#b2b-system");
